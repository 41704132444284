import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
const MetaData = ({ title }) => {
 //const canonicalUrl=localStorage.getItem("canonicalUrl");
 const location = useLocation();
 //console.log(canonicalUrl);
 //console.log("location: ",location.pathname);
 const canonicalUrl=`https://www.zoplar.com${location.pathname}`;
 //console.log("canonicalUrl: ",canonicalUrl);
  return (
    <Helmet>
      <title>{title }</title>
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Helmet>
  );
};

export default MetaData;