import React ,{useRef} from "react" ;
import "./Privacy.css"

const Return_Cancellation =() =>{

    const currentDate = new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      });
      


      const terms = useRef(null);
      const Process = useRef(null);
      const termandconditions = useRef(null);
      const returnPolicy = useRef(null);
      const cancellationPolicy=useRef(null);
      const Eligibility=useRef(null);
      const opting=useRef(null);
      const security=useRef(null);
      const contact=useRef(null);
      const updates=useRef(null);
      const record=useRef(null);
      // Add refs for other sections as needed
    
      const scrollToSection = (ref) => {
        if (ref && ref.current) {
          const rect = ref.current.getBoundingClientRect();
          const offset = rect.top + window.scrollY; // Calculate the absolute position

          const adjustedOffset = offset - 80
    
          window.scrollTo({
            top: adjustedOffset,
            behavior: "smooth",
          });
        }
      };

    return(
        <div className="privacypage">
            <div className="privacybanner">
                <h1 style={{ fontSize: "3rem" }}>Zoplar's 7-Day Return & Cancellation Policy</h1>
            </div>
            <div className="privacyterms">
            <div ref={Eligibility}>
                
                <h1>Return Policy</h1>
  <h5 style={{ fontSize: "1.7rem" }}>1. Eligibility</h5>
  <p style={{ fontSize: "1.19rem" }}>
    You may raise a return/replacement request for any of the following reasons:
  </p>
  <p>
    <ul style={{ paddingLeft: "1.5rem" }}>
      <li style={{ fontSize: "1.1rem", marginBottom: "0.8rem"   }}>
        <strong>Device is physically damaged in transit:</strong> (Ideally, you
        should not accept the package if you receive a damaged consignment as
        evident from the packaging.)
      </li>
      <li style={{ marginBottom: "0.8rem" }}>
        <strong>Device is not functioning as expected</strong> due to a
        manufacturing defect.
      </li>
      <li style={{ marginBottom: "0.8rem" }}>
        <strong>Missing parts or accessories:</strong> If any
        component/accessory that should be included is missing.
      </li>
      <li style={{ marginBottom: "0.8rem" }}>
        <strong>Received the wrong device:</strong> In case of incorrect
        shipment.
      </li>
    </ul>
  </p>
  <p style={{ fontSize: "1.19rem" }}>
    <strong>Note:</strong> Returns or replacements are not applicable for new
    devices.
  </p>
</div>

                 
<div ref={Process}>
  <h5 style={{ fontSize: "1.7rem" }}>2. Process</h5>
  <p style={{ fontSize: "1.19rem" }}>
  You are required to raise your request at <strong>customer.support@zoplar.com</strong> or by contacting your Zoplar sales representative with the necessary documentation/proof, including but not limited to:
  </p>
  <p>
    <ol style={{ paddingLeft: "2rem" }}>
      <li style={{ fontSize: "1.1rem", marginBottom: "0.8rem"   }}>
      Photos/videos of the device showing the issue/defect.
      </li>
      <li style={{ marginBottom: "0.8rem" }}>
      Photo of the shipping label with order ID.
      </li>
      <li style={{ marginBottom: "0.8rem" }}>
      Copy of the bill/invoice.
      </li>
    </ol>
  </p>
  <p style={{ fontSize: "1.19rem" }}>
  If any further clarification is needed, a Zoplar executive will reach out to you before approving the return request via email. Zoplar may not be able to provide a replacement in case of incomplete or inaccurate information.
  </p>
  <p>
    <ul style={{ paddingLeft: "2rem" }}>
      <li style={{ fontSize: "1.1rem", marginBottom: "0.8rem"   }}>
      The return collection process will be initiated within 3 working days of request approval.
      </li>
      <li style={{ marginBottom: "0.8rem" }}>
      Once the returned device is received, the QA/QC team will inspect and approve the return. The replacement process will be initiated within 7 working days of approval.
      </li>
    </ul>
  </p>
</div>
                                 
<div ref={termandconditions}>
  <h5 style={{ fontSize: "1.7rem" }}>3. Terms and Conditions</h5>
  <p style={{ fontSize: "1.19rem" }}>
   <strong>Request Timeline:</strong> Return requests must be initiated within <strong> 7 days </strong> of installation.
  </p>
  <p>
  <ul style={{ paddingLeft: "1.5rem"}}> 
  <li style={{ fontSize: "1.1rem", marginBottom: "0.8rem" }}>
    <strong>Condition of the Device:</strong>
  </li>
 <ul style={{ paddingLeft: "1.5rem"}}>
 <li style={{ marginBottom: "0.8rem" }}>
    The device should be unused and undamaged.
  </li>
  <li style={{ marginBottom: "0.8rem" }}>
    No external repairs or modifications (e.g., scratches, dents, or replaced parts) should have been done.
  </li>
 </ul>
</ul>
  </p>
  <p>
  <ul style={{ paddingLeft: "1.5rem"}}> 
  <li style={{ fontSize: "1.1rem", marginBottom: "0.8rem" }}>
    <strong>Return Exclusions:</strong>
  </li>
 <ul style={{ paddingLeft: "1.5rem"}}>
 <li style={{ marginBottom: "0.8rem" }}>
 Returns are not accepted for <strong>  new devices </strong> .
  </li>
  <li style={{ marginBottom: "0.8rem" }}>
  Certain high-value equipment like <strong> CT, MRI, Cath Lab, Dexa Scan, and Lithotripsy machines </strong>, as well as consumables and accessories, are not eligible for return.
  </li>
 </ul>
</ul>
  </p>
  <p style={{ fontSize: "1.19rem" }}>If the requested device is out of stock, a refund will be issued through payment gateway/credit note/coupon.
  </p>
  <p style={{ fontSize: "1.19rem" }}>Zoplar reserves the right to deny a request if it considers the return to not be genuine.
  </p>
</div>
                 
                {/* <div ref={record }>
                    <h5>Record Keeping and Quality Assurance:</h5>
                    <p>All calls and messages initiated by Zoplar are recorded for internal quality assurance and training purposes. This information is strictly used within the organization and is not shared with external parties.</p>
                </div>
                
                <div ref={updates }>
                    <h5>Updates to the Privacy Policy:</h5>
                    <p>Zoplar reserves the right to update this Privacy Policy as necessary. Users will be notified of any material changes.</p>             
                </div> */}
                
                <div ref={returnPolicy}>
  <h5 style={{ fontSize: "2rem" }}>Refund Policy</h5>
  <p style={{ fontSize: "1.19rem" }}>
  Refunds are initiated upon confirmation of cancellation and receipt of the returned device. You will receive a confirmation email upon refund processing.
  </p>
  <p style={{ fontSize: "1.19rem" }}><strong>Refund Modes:</strong></p>
  <p>
    <ul style={{ paddingLeft: "1.5rem" }}>
      <li style={{ fontSize: "1.1rem", marginBottom: "0.8rem"   }}>
      Refunds to <strong> Zoplar Wallet </strong> are processed within <strong>24 hours</strong> of order cancellation.
      </li>
      <li style={{ marginBottom: "0.8rem" }}>
      Refunds to cards/bank accounts/UPI are processed within <strong>7 days</strong> of return verification.
      </li>
      <li style={{ marginBottom: "0.8rem" }}>
      Refunds via <strong>NEFT/RTGS </strong>are processed within <strong>10 days</strong>.
      </li>
      
    </ul>
  </p>
  <p style={{ fontSize: "1.19rem" }}>
    <strong>Note:</strong> Returns or replacements are not applicable for new
    devices.
  </p>
</div>

<div ref={cancellationPolicy}>
  <h5 style={{ fontSize: "2rem" }}>Order Cancellation Policy</h5>
  <p>
    <ul style={{ paddingLeft: "1.5rem" }}>
      <li style={{ fontSize: "1.1rem", marginBottom: "0.8rem"   }}>
      <strong>Cancellation Period:</strong> Orders can be canceled within <strong>6 hours</strong> of placement, provided they have not been shipped.
      </li>
      <li style={{ marginBottom: "0.8rem" }}>
      <strong> Delayed Delivery: </strong>Orders delayed by more than <strong> 21 days </strong> from the date of placement can be canceled (excludes custom orders and large equipment).
      </li>
      <li style={{ marginBottom: "0.8rem" }}>
      Cancellation for custom or specially procured items is at Zoplar’s discretion.
      </li>  
    </ul>
  </p>
</div>
                

            </div>
            
            <div className="privacypolicy">
                <h5>Return & Cancellation</h5>
                <h6>Effective Date : <span> {currentDate}</span></h6>
                <div className="privacybottomline"></div>
                <div className="privacytable">
                    <h5>Table of Contents</h5>
                    <p><span onClick={() => scrollToSection(Eligibility)}>1. Eligibility</span></p>
                    <p><span onClick={() => scrollToSection(Process)}>2. Process</span></p>
                    <p><span onClick={() => scrollToSection(termandconditions)}>3. Terms and Conditions</span></p>
                    <p><span onClick={() => scrollToSection(returnPolicy)}>4. Refund Policy</span></p>
                    <p> <span onClick={() => scrollToSection(cancellationPolicy)} >5. Cancellation Policy</span></p>
                    {/* <p><span onClick={() => scrollToSection(record)} >Record Keeping and Quality Assurance:</span></p>
                    <p><span onClick={() => scrollToSection(updates)} >Updates to the Privacy Policy:</span></p> */}
                    {/* <p><span onClick={() => scrollToSection(contact)} >Contact Information:</span></p> */}

                </div>
            </div>

        </div>

    );
};

export default Return_Cancellation

